'use-client'
import React from 'react';
import styled from 'styled-components';
import TixyLogo from '../partials/tixyLogo';
import TranslateText from '../../utils/translateText';
import Link from 'next/link';
import {
  linkProductAnalytics,
  linkAbout,
  linkContact,
  linkProductOptimization,
  linkProductAutomation
} from '../links';  // Updated the import path for the links
import { darkGreyText } from '../../landingPage/styledComponents';


const BottomBorder = styled.div`
  display: block;
  width:100vw;
  border-bottom: 0.5px solid #ffffff50;
  z-index: 5;
`;

const OuterDiv = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1350px;

  @media screen and (max-width: 1350px) {
    width: 90vw;
    flex-direction: column;
  }
`;

const RowWrap = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2rem;

  @media screen and (max-width: 1350px) {
    flex-direction: column;
  }
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items:center;
  height: 100%;
  gap:12px;
  width: auto;
`;

const ImageWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width:120px;
  height:60px;
  margin-top: -10px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const ImageWrapMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width:100%;
  height:80px;

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

const ImageSizeMobile = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width:100px;
  height:60px;

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

const TitleText = styled.span`
    color:#f1f1f1;
    font-size: 1.05rem;
    text-align: left;
    width: 100%;
    margin-bottom: 4px;
`;

const TextLink = styled.div`
    display: block;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${darkGreyText};
    font-size: 0.95rem;
    text-align: left;
    width: 100%;
    line-height: 20px;
    &:hover{
      color:#f1f1f1;
      cursor: pointer;
    }
`;

const WebsiteRights = styled.small`
  color: #ffffff99;
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: .5px;
  line-height: 15px;
  width: 80%;
  font-weight: 400;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;

const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.6px solid #f1f1f150;
  height: 1px;
  max-width: 1350px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 1350px) {
      width:90vw;
  }
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);  // 3 columns for 3 categories
  width:100%;
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));  // fill width and stack as needed
    column-gap: 0px;
    row-gap: 2rem;
  }
`;

const columns = [
  {
    title: { swedish: "Om Paretro", english: "About" },
    items: [
      { link: linkContact, swedish: "Kontakt", english: "Contact" },
      { link: null, swedish: "Linkedin", english: "Linkedin" },
      { link: linkAbout, swedish: "Om oss", english: "Link 1" },
    ]
  },
  {
    title: { swedish: "Produkter", english: "Products" },
    items: [
      { link: linkProductOptimization, swedish: "Plats-optimering", english: "Spot Optimization" },
      { link: linkProductAutomation, swedish: "Process-automation", english: "Process Automation" },
      { link: linkProductAnalytics, swedish: "Avancerad analys", english: "Advanced Analytics" },
    ]
  },
  {
    title: { swedish: "Resurser", english: "Resources" },
    items: [
      { link: "https://www.tixylogistics.com", swedish: "Inloggning", english: "Sign in" },
    ]
  }
];

export default function Footer() {

  return (
    <>
      <BottomBorder />
      <OuterDiv>
        <RowWrap>
          <ImageWrapMobile>
            <ImageSizeMobile>
              <TixyLogo />
            </ImageSizeMobile>
          </ImageWrapMobile>
          <CategoriesGrid>
            <ImageWrap>
              <TixyLogo />
            </ImageWrap>
            {columns.map((column, index) => (
              <ColumnDiv key={index}>
                <TitleText>
                  <TranslateText swedish={column.title.swedish} english={column.title.english} />
                </TitleText>
                {column.items.map((item, i) => (
                  item.link ? (
                    <TextLink key={item.swedish} >
                      <Link href={item.link} passHref><TranslateText swedish={item.swedish} english={item.english} /></Link>
                    </TextLink>
                  ) : (
                    <TextLink key={i}>
                      <TranslateText key={i} swedish={item.swedish} english={item.english} />
                    </TextLink>
                  )
                ))}
              </ColumnDiv>
            ))}
          </CategoriesGrid>
        </RowWrap>
        <Divider />
        <WebsiteRights> {`©${new Date().getFullYear()} Tixy Ab All rights reserved`}</WebsiteRights>
      </OuterDiv>
    </>
  );
}
